import React, { useState, useEffect } from 'react';
import { Container, Heading, Flex, Box, Text, Button } from '@radix-ui/themes';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import YouTube from 'react-youtube';
import Modal from 'react-modal';
import '../../styles/HowItWorks.css'
import { Link } from 'react-router-dom';

// Asegúrate de establecer el elemento raíz de la aplicación para el modal
Modal.setAppElement('#root'); // Ajusta esto si tu elemento raíz tiene un id diferente

const Step = ({ number, title, description, isMobile }) => (
  <Box
    style={{
      flex: '1 1 250px',
      textAlign: 'center',
      maxWidth: isMobile ? '100%' : '350px', // Ajustar según el dispositivo
      border: '2px solid black',
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '16px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      margin: isMobile ? '0 auto' : undefined, // Centrar en móvil
      width: isMobile ? 'calc(100% - 2rem)' : undefined,
    }}
  >
    <Box
      style={{
        color: 'white',
        width: '40px',
        height: '40px',
        //borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto 16px',
        fontSize: '30px',
        fontWeight: 'bold'
      }}
    >
      {number}
    </Box>
    <Heading className='title-card-howit'>{title}</Heading>
    <Text className='description-card-howit'>{description}</Text>
  </Box>
);

const HowItWorks = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);


  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        setActiveStep((prev) => (prev === 2 ? 0 : prev + 1));
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [isMobile]);



  const steps = [
    {
      number: "1️⃣",
      title: "Instala el complemento de Google Sheets",
      description: "Encuentra nuestra extensión en Google Workspace y añádela a tu cuenta en segundos."
    },
    {
      number: "2️⃣",
      title: "Conecta tu cuenta de Sheetseller",
      description: "Ingresa las credenciales proporcionadas y sincroniza tus datos de Mercado Libre."
    },
    {
      number: "3️⃣",
      title: "Descubre un nuevo mundo de información",
      description: "Accede a métricas actualizadas, genera reportes personalizados y optimiza tu negocio con facilidad."
    }
  ];



  const videoOpts = {
    height: '100%',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };




  return (
    <Container size="5" py="9" style={{ backgroundColor: '#f5f5f5' }}>
      <Heading className='title-howit'>
        Empezar a utilizarlo es fácil
      </Heading>
      {isMobile ? (
        <div className="mobile-steps-carousel">
          <div className="steps-container">
            <div
              className="steps-slider"
              style={{
                transform: `translateX(-${activeStep * 100}%)`
              }}
            >
              {steps.map((step, index) => (
                <Step
                  key={index}
                  number={step.number}
                  title={step.title}
                  description={step.description}
                  isMobile={isMobile}
                />
              ))}
            </div>
          </div>
          <div className="step-indicators">
            {steps.map((_, index) => (
              <div
                key={index}
                className={`step-dot ${index === activeStep ? 'active' : ''}`}
                onClick={() => setActiveStep(index)}
              />
            ))}
          </div>
        </div>
      ) : (
        <Flex justify="center" gap="3" wrap="wrap" style={{ marginBottom: '80px' }}>
          {steps.map((step, index) => (
            <Step
              key={index}
              number={step.number}
              title={step.title}
              description={step.description}
              isMobile={isMobile}
            />
          ))}
        </Flex>
      )}

      {/* <Box style={{ textAlign: 'center', width: '100%', maxWidth: '1000px', margin: '0 auto', aspectRatio: '16/9', position: 'relative' }}>
        <div
          onClick={openModal}
          style={{
            cursor: 'pointer',
            width: '100%',
            height: '100%',
            position: 'relative',
            borderRadius: '8px',
            overflow: 'hidden'
          }}
        >
          <img
            src="https://img.youtube.com/vi/iighYGd_Ehw/maxresdefault.jpg"
            alt="Click to play video"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '8px',
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '68px',
              height: '48px',
              backgroundColor: 'rgba(0,0,0,0.7)',
              borderRadius: '14px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div
              style={{
                width: '0',
                height: '0',
                borderTop: '10px solid transparent',
                borderBottom: '10px solid transparent',
                borderLeft: '20px solid white'
              }}
            />
          </div>
        </div>
      </Box> */}


      <Flex
        gap="6"
        align="center"
        justify="center"
        style={{
          marginTop: '2rem',
          width: '100%'
        }}
      >
        <Link style={{ 'textDecoration': 'none' }} to={'/pricing'}>
          <Button
            className="custom-button-how"
          >
            ¡Comienza gratis ahora!
            <CursorArrowIcon style={{ marginLeft: '8px' }} />
          </Button>
        </Link>
        <Link to={'/demo'} style={{ textDecoration: 'none' }}>
          <Button
            /* variant="outline" */
            className="outline-button-how"
          >
            Probar Demo
          </Button>
        </Link>
      </Flex>


      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '0',
            border: 'none',
            background: 'none',
            width: '90%', // Cambiado a porcentaje
            aspectRatio: '16/9', // Mantiene la proporción
            maxWidth: '1200px',
            overflow: 'hidden'
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)',
            zIndex: 1000,
            overflow: 'hidden'
          }
        }}
      >
        <div style={{ width: '100%', height: '100%', position: 'relative', overflow: 'hidden' }}>
          <YouTube videoId="iighYGd_Ehw" opts={videoOpts} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
        </div>
      </Modal>  */}



    </Container>
  );
};

export default HowItWorks;
// Modificaciones al primer componente Hero
import * as React from 'react';
import { Container, Heading, Text, Button, Flex, Box } from '@radix-ui/themes';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import TrustedCompanies from './TrustedCompanies';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import '../../styles/SharedHero.css';

const Hero = () => (
  <Box className="shared-hero-container">
    <Box className="shared-hero-main-section">
      <Container size="5" className="shared-hero-container-inner">
        <Flex direction="column" className="shared-hero-content">
          <Heading className="shared-hero-title">
            Transforma tus datos en decisiones inteligentes
          </Heading>
          <Text className="shared-hero-description">
            Sincroniza automáticamente tus métricas de Mercado Libre con Google Sheets en tiempo real 24/7
          </Text>
          <Flex className="shared-hero-buttons">
            <ScrollLink to='HowItWorks' smooth={true}>
              <Button size="4" className="shared-button-primary">
                Cómo Funciona
                <CursorArrowIcon className="shared-button-icon" />
              </Button>
            </ScrollLink>
            <Link style={{ textDecoration: 'none' }} to={'/demo'}>
              <Button size="4" className="shared-button-secondary">
                Probar Demo
              </Button>
            </Link>
          </Flex>
        </Flex>
      </Container>
    </Box>
    <Box py="4" style={{ backgroundColor: 'white' }}>
      <Container>
        <TrustedCompanies />
      </Container>
    </Box>
  </Box>
);

export default Hero;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import template_start from '../../assets/images/templat/template_start.png';
import './css/TemplatesGrid.css';

const TemplateCard = ({ title, description, slug }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/template/${slug}`);
  };

  return (
    <div className="template-card" role="article">
      <div className="template-card-content">
        <h3 className="template-card-title">{title}</h3>
        <p className="template-card-description">{description}</p>
        <div className="template-card-button-container">
          <button
            className="template-card-button"
            onClick={handleClick}
            aria-label={`Ver más sobre ${title}`}
          >
            Descubre más
          </button>
        </div>
      </div>
    </div>
  );
};

const TemplatesGrid = () => (
  <div className="templates-grid-container">
    <div className="templates-grid-content">
      <h2 className="templates-grid-heading">Nuestras plantillas</h2>
      {/* <div className="templatesgrid-image-container">
        <img src={template_start} alt="Ejemplo de plantilla" className="templatesgrid-image" />
      </div> */}
      <div className="templates-grid">
        <TemplateCard title="🚀 Guía Rápida Sheetseller" description="Aprende a utilizar Sheetseller en menos de 15 minutos." slug="guia-rapida" />
        <TemplateCard title="📊 Monitoreo de Publicaciones" description="Visualiza rápidamente la información importante de tus publicaciones." slug="monitoreo-publicaciones" />
        <TemplateCard title="🎯 Pronóstico de Reabastecimiento Full" description="Calcula exactamente cuánto stock enviar a Full basado en datos reales." slug="pronostico" />
        {/* <TemplateCard title="Detalles de tus productos" description="Visualiza tus productos con algunos detalles en específico." slug="detalles-productos" />
        <TemplateCard title="Retiros" description="Visualiza tus retiros junto con los productos que se incluyen." slug="retiros" />
        <TemplateCard title="Ventas" description="Analiza tus ventas actuales y algunos detalles extra." slug="ventas" />
        <TemplateCard title="Competencia" description="Mantén el análisis del estado de tus publicaciones catálogo." slug="competencia" />
        <TemplateCard title="Devoluciones" description="Obtén las devoluciones de tus productos." slug="devoluciones" /> */}
      </div>
    </div>
  </div>
);

export default TemplatesGrid;
// Modificaciones al componente ReviewsHero
import React from 'react';
import { Box, Container, Text, Heading, Button, Flex } from '@radix-ui/themes';
import { Link } from 'react-router-dom';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import '../../styles/SharedHero.css'; // Nuevo archivo CSS compartido

const ReviewsHero = () => {
  return (
    <Box className="shared-hero-container">
      <Container size="5" className="shared-hero-container-inner">
        <Box className="shared-hero-content">
          <Heading className="shared-hero-title">
            Automatiza tu Negocio en Mercado Libre
          </Heading>
          <Text className="shared-hero-description">
            Ahorra +15 horas semanales y toma decisiones más inteligentes con datos en tiempo real
          </Text>
          <p>{' '}</p>
          <Text className="shared-hero-feature-list">
            ⚡ 3 minutos para comenzar<br />
            📊 Actualización automática<br />
            🔄 Integrado con Google Sheets
          </Text>
          <Flex className="shared-hero-buttons">
            <Link style={{ textDecoration: 'none' }} to={'/pricing'}>
              <Button size="4" className="shared-button-primary">
                ¡Comienza gratis ahora!
                <CursorArrowIcon className="shared-button-icon" />
              </Button>
            </Link>
            <Link to={'/demo'} style={{ textDecoration: 'none' }}>
              <Button size="4" className="shared-button-secondary">
                Probar Demo
              </Button>
            </Link>
          </Flex>
        </Box>
      </Container>
    </Box>
  );
};

export default ReviewsHero;
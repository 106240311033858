// Modificaciones al segundo componente Hero
import { Container, Heading, Text, Button, Flex, Box } from '@radix-ui/themes'
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { useNavigate } from 'react-router-dom'
import '../../styles/SharedHero.css' // Nuevo archivo CSS compartido

const Hero = () => {
  const navigate = useNavigate()

  const scrollToTemplates = () => {
    const element = document.getElementById('templates-grid')
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <Box className="shared-hero-container">
      <Container size="4" className="shared-hero-container-inner">
        <Flex direction="column" className="shared-hero-content">
          <Heading className="shared-hero-title">
            Plantillas de Google Sheets + Mercado Libre | 100% Gratis
          </Heading>
          <Text className="shared-hero-description">
            Sincroniza y analiza tus ventas en tiempo real con plantillas pre-configuradas para Sheetseller. Datos actualizados automáticamente.
          </Text>
          <Flex className="shared-hero-buttons">
            <Button
              size="4"
              className="shared-button-primary"
              onClick={scrollToTemplates}
            >
              Ver plantillas
              <MagnifyingGlassIcon className="shared-button-icon" />
            </Button>
            <Button
              size="4"
              className="shared-button-secondary"
              onClick={() => navigate("/demo")}
            >
              Probar demo
            </Button>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default Hero
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Heading, Text, Button, Flex, Container } from '@radix-ui/themes';
import { FaDatabase, FaChartBar, FaBoxes, FaChartLine, FaClock } from 'react-icons/fa';
import ventas_inventario from '../../assets/images/templat/templates_images/ventas-inventario.png'
import pronosticador from '../../assets/images/templat/templates_images/imagen_pronosticador.png'
import entrenamiento from '../../assets/images/templat/templates_images/imagen_entrenamiento.png'
import publicaciones from '../../assets/images/templat/templates_images/imagen_publicaciones.png'
import GettingStarted from './GettingStarted';
import GameChanger from './GameChanger';
import TrustedCompanies from '../common/TrustedCompanies'
import SyncBanner from './SyncBanner';
import KeyFeatures from './KeyFeatures';
import VisualStorytelling from './VisualStorytelling'
import './css/TemplatePage.css';
import HowItWorks from '../common/HowItWorks';

const templateData = {
  'guia-rapida': {
    title: '🚀 Guía Rápida Sheetseller',
    description: 'Aprende a utilizar Sheetseller en menos de 15 minutos.',
    image: entrenamiento,
    url_template: "https://docs.google.com/spreadsheets/d/1PnMQBcXrZ07tQvbj8F6D1q84sSlYJJNEG1klPqITOJI/copy",
    features: {
      title: "Características y Beneficios Principales",
      subtitle: "🚀 Guía Rápida Sheetseller",
      description: "⭐ Comienza ahora: Entrenamiento express para dominar tu gestión en Mercado Libre.",
      features: [
        { icon: "⚡", text: "Inicio Rápido", description: "Domina la extensión en solo 15 minutos con nuestra guía paso a paso." },
        { icon: "🔗", text: "Conexión en Vivo", description: "Prueba con tus propios datos conectando tu cuenta de Mercado Libre." },
        { icon: "📋", text: "Platilla Personalizada", description: "Obtén tu copia privada y personalizable de la plantilla en un clic." },
        { icon: "📊", text: "Práctica Interactiva", description: "Explora y prueba todas las funciones de la extensión en tiempo real." }
      ]
    },
    storytelling: {
      title: "Visualización de Datos",
      description: "Visualiza tus datos de ventas e inventario de manera clara y efectiva. Toma decisiones informadas con información actualizada en tiempo real.",
      features: [
        { icon: <FaChartLine />, text: "Vista General Simplificada" },
        { icon: <FaDatabase />, text: "Datos Organizados" },
        { icon: <FaClock />, text: "Actualizaciones en Tiempo Real" }
      ]
    }
  },
  'monitoreo-publicaciones': {
    title: '📊 Monitoreo de Publicaciones',
    description: 'Visualiza rápidamente la información importante de tus publicaciones.',
    image: publicaciones,
    url_template: "https://docs.google.com/spreadsheets/d/1j0McrIlRP9Ab2j5f_D_fG-N9-LQ43an7QUUz0Ta_wTI/copy",
    features: {
      title: "Características y Beneficios Principales",
      subtitle: "📊 Monitoreo de Publicaciones",
      description: "⭐ Todo el poder de tu tienda en un vistazo: Monitorea tus publicaciones en tiempo real.",
      features: [
        { icon: "📱", text: "Vista en Tiempo Real", description: "Visualiza el estado actual de todas tus publicaciones en un solo Dashboard." },
        { icon: "📈", text: "Métricas Principales", description: "Controla ventas, comisiones y tendencias de cada publicación al instante." },
        { icon: "🔍", text: "Detalles Completos", description: "Accede a características, variantes y configuraciones de cada producto publicado." },
        { icon: "📊", text: "Análisis de Cuenta", description: "Monitorea el rendimiento general de tu cuenta de Mercado Libre." }
      ]
    }
  },
  'pronostico': {
    title: '🎯 Pronóstico de Reabastecimiento Full',
    description: "Calcula exactamente cuánto stock enviar a Full basado en datos reales.",
    image: pronosticador,
    url_template: "https://docs.google.com/spreadsheets/d/1mTxTr5F8Oy_6Go_wZpaPD7srKAvopXKH_lRV5E1EDhE/copy",
    features: {
      title: "Características y Beneficios Principales",
      subtitle: "🎯 Pronóstico de Reabastecimiento Full",
      description: "⭐ Optimiza tu logística Full: Pronósticos precisos para nunca quedarte sin stock.",
      features: [
        { icon: "📦", text: "Stock Full", description: "Monitorea en tiempo real tus niveles de inventario en centros logísticos." },
        { icon: "🎯", text: "Predicción de Demanda", description: "Anticipa tus necesidades de reposición basado en histórico de ventas." },
        { icon: "⏱️", text: "Tiempos de Envío", description: "Calcula los tiempos óptimos para enviar nuevos productos al centro Full." },
        { icon: "💡", text: "Recomendaciones Smart", description: "Recibe alertas inteligentes sobre cuándo y cuánto reabastecer." }
      ]
    }
  },
  'detalles-productos': {
    title: 'Detalles de tus productos',
    description: 'Visualiza tus productos con algunos detalles en específico.',
    image: ventas_inventario
  },
  'retiros': {
    title: 'Retiros',
    description: "Visualiza tus retiros junto con los productos que se incluyen.",
    image: ventas_inventario
  },
  'ventas': {
    title: 'Ventas',
    description: 'Analiza tus ventas actuales y algunos detalles extra.',
    image: ventas_inventario
  },
  'competencia': {
    title: 'Competencia',
    description: 'Mantén el análisis del estado de tus publicaciones catálogo.',
    image: ventas_inventario
  },
  'devoluciones': {
    title: 'Devoluciones',
    description: 'Obtén las devoluciones de tus productos.',
    image: ventas_inventario
  }
};

const TemplatePage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const template = templateData[slug];

  console.log('template', template);

  if (!template) {
    return <div>Plantilla no encontrada</div>;
  }

  return (
    <Box className="template-page-wrapper">
      <Container size="3" className="template-page">
        <Flex direction="column" align="center" gap="6">
          <Box className="template-content" text-align="center">
            <Text className="template-subtitle">Plantillas gratuitas de Sheetseller</Text>
            <Heading className="template-title">{template.title}</Heading>
            <Text className="template-description">{template.description}</Text>
          </Box>

          <Box className="template-image-container">
            <img src={template.image} alt={template.title} className="template-hero-image" />
          </Box>

          <Button
            size="4"
            className="template-start-button"
            onClick={() => window.open(template.url_template, '_blank')}
          /* onClick={() => window.location.href = template.url_template} */
          >
            Obtener Plantilla Gratis
          </Button>
        </Flex>
      </Container>

      <Box className="additional-components" style={{ color: 'black' }}>
        <Box py="4" style={{ backgroundColor: 'white', color: 'black' }}>
          <Container>
            <TrustedCompanies />
          </Container>
        </Box>
        <HowItWorks />
        <KeyFeatures features={template.features} image={template.image} url_template={template.url_template} />
        <SyncBanner />
        {/* <VisualStorytelling storyData={template.storytelling} image={template.image} />
        <GameChanger /> */}
      </Box>
    </Box>
  );
};

export default TemplatePage;
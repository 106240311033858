import React, { useState, useEffect } from 'react';
import { Container, Heading, Flex, Box, Text } from '@radix-ui/themes';
import { FaStar, FaQuoteLeft, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import styled from 'styled-components';

const StyledContainer = styled(Container)`
  background-color: #ffffff;
  padding: 4rem 0;

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const ReviewsHeader = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 2rem;
    text-align: center;
  }
`;

const NavigationButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #666;
  cursor: pointer;
  padding: 0.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #02A724;
  }
`;



const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : 0,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};




const TestimonialCard = ({ content, author, role, avatar, highlightedText }) => {
  const renderHighlightedContent = (text, highlight) => {
    if (!highlight) return text;

    const parts = text.split(highlight);
    return (
      <>
        {parts.map((part, i) => (
          <React.Fragment key={i}>
            {part}
            {i < parts.length - 1 && (
              <span style={{ backgroundColor: '#FFF9C4' }}>{highlight}</span>
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <Box
      style={{
        backgroundColor: 'white',
        padding: '2rem',
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between' // Esto ayudará a alinear el footer
      }}
    >
      <Box>
        <FaQuoteLeft size={24} color="#02A724" style={{ marginBottom: '1rem' }} />
        <Text style={{ marginBottom: '2rem', lineHeight: '1.6', flex: 1, fontSize: '1rem' }}>
          {renderHighlightedContent(content, highlightedText)}
        </Text>
      </Box>
      <Flex align="center" gap="3" style={{ marginTop: 'auto' }}> {/* Esto mantendrá el footer alineado */}
        {avatar ? (
          <img
            src={avatar}
            alt={author}
            style={{
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              objectFit: 'cover'
            }}
          />
        ) : (
          <Box
            style={{
              width: '48px',
              height: '48px',
              borderRadius: '50%',
              backgroundColor: '#E0E0E0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: '20px',
              color: '#666'
            }}
          >
            {author.charAt(0)}
          </Box>
        )}
        <Box>
          <Text style={{ fontWeight: 'bold', fontSize: '1rem' }}>{author} <br /></Text>
          <Text style={{ color: '#666', fontSize: '0.75rem' }}>{role}</Text>
        </Box>
      </Flex>
    </Box>
  );
};

const testimonials = [
  {
    content: "SheetSeller es la única herramienta que me da acceso directo a mis datos, sin depender de reportes predefinidos. Ahora tengo el control total para trabajar con ellos como quiera.",
    author: "María González",
    role: "Vendedora en Mercado Libre",
    avatar: "https://images.pexels.com/photos/3763188/pexels-photo-3763188.jpeg?cs=srgb&dl=pexels-olly-3763188.jpg&fm=jpg",
    highlightedText: "la única herramienta que proporciona los datos directamente"
  },
  {
    content: "Antes, extraer datos manualmente me tomaba horas. Con SheetSeller, he reducido drásticamente el tiempo que dedico a la gestión de información. ¡Es un cambio total!",
    author: "Juan Pérez",
    role: "Vendedor en Mercado Libre",
    avatar: "https://images.unsplash.com/photo-1600180758890-6b94519a8ba6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    highlightedText: "reducido significativamente"
  },
  {
    content: "He probado muchos softwares para gestionar mis métricas, pero siempre me sentía limitado. Con SheetSeller, puedo personalizar mis hojas de cálculo exactamente como las necesito. ¡Es justo lo que buscaba!",
    author: "Ana Torres",
    role: "Vendedora en Mercado Libre",
    avatar: "https://media.istockphoto.com/id/1471845315/photo/happy-portrait-or-business-woman-taking-a-selfie-in-office-building-for-a-social-media.jpg?s=612x612&w=0&k=20&c=AOylBL01joI0zphCAFr6YVrsOgp_jd2XtVUychLXYho=",
    highlightedText: "siempre los encontraba limitados"
  },
  {
    content: "La flexibilidad de SheetSeller es insuperable. Ahora puedo crear reportes personalizados que se adaptan perfectamente a las necesidades de mi negocio. ¡Es una herramienta imprescindible!",
    author: "Roberto Sánchez",
    role: "Vendedor en Mercado Libre",
    avatar: "https://images.unsplash.com/photo-1522075469751-3a6694fb2f61?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    highlightedText: "flexibilidad"
  }
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  return (
    <StyledContainer>
      <ReviewsHeader>
        <Flex gap="1">
          {[...Array(5)].map((_, i) => (
            <FaStar key={i} color="#FFD700" size={isMobile ? 20 : 24} />
          ))}
        </Flex>
        <Heading style={{
          margin: isMobile ? '0.5rem 0' : '0 1rem',
          fontSize: isMobile ? '1.5rem' : '1.777rem'
        }}>
          Opiniones de nuestros clientes
        </Heading>
        <Flex gap="2">
          <NavigationButton onClick={handlePrev}>
            <FaChevronLeft />
          </NavigationButton>
          <NavigationButton onClick={handleNext}>
            <FaChevronRight />
          </NavigationButton>
        </Flex>
      </ReviewsHeader>

      <div style={{ textAlign: 'center' }}>
        <Text style={{
          fontSize: isMobile ? '1.1rem' : '1.333rem',
          padding: isMobile ? '0 1rem' : '0'
        }}>
          SheetSeller ha transformado la forma en que los vendedores de Mercado Libre gestionan sus datos. ¡Descubre lo que dicen nuestros usuarios!
        </Text>
      </div>

      <Box style={{
        position: 'relative',
        overflow: 'hidden',
        marginTop: '2rem',
        padding: isMobile ? '0' : '0', // Quitamos el padding en móvil
        width: '100%'
      }}>
        <Flex
          style={{
            display: 'flex',
            gap: isMobile ? '2rem' : '2rem',
            padding: '0 1rem',
            transition: 'transform 0.5s ease-in-out',
            transform: isMobile
              ? `translateX(-${currentIndex * 100}%)`
              : `translateX(-${(currentIndex % testimonials.length) * (100 / 3)}%)`
          }}
        >
          {[...testimonials, ...testimonials].map((testimonial, index) => (
            <Box
              key={`testimonial-${index}`}
              style={{
                flex: isMobile ? '0 0 100%' : '0 0 calc(33.333% - 1.33rem)',
                minWidth: isMobile ? '100%' : 'calc(33.333% - 1.33rem)',
                transition: 'opacity 0.5s ease-in-out',
                padding: isMobile ? '0 1rem' : '0'
              }}
            >
              <TestimonialCard {...testimonial} />
            </Box>
          ))}
        </Flex>
      </Box>
    </StyledContainer>
  );
};

export default Testimonials;
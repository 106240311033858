// Modificaciones al componente PricingHero
import React from 'react';
import { Container, Heading, Text, Button, Box } from '@radix-ui/themes';
import { CursorArrowIcon } from '@radix-ui/react-icons';
import { Link as ScrollLink } from 'react-scroll';
import '../../styles/SharedHero.css'; // Nuevo archivo CSS compartido

const PricingHero = () => {
  return (
    <Box className="shared-hero-container">
      <Container size="5" className="shared-hero-container-inner">
        <Box className="shared-hero-content">
          <Heading className="shared-hero-title">
            Automatiza tus métricas de Mercado Libre en tiempo real.
          </Heading>
          <Text className="shared-hero-description">
            Ahorra tiempo con nuestra integración directa a Google Sheets.
          </Text>
          <p>{' '}</p>
          <Text className="shared-hero-subtitle">
            Prueba gratis por 15 días y encuentra el plan perfecto para tu negocio.
          </Text>
          <Box className="shared-hero-buttons">
            <ScrollLink to='PricingPlans' smooth={true}>
              <Button size="4" className="shared-button-primary">
                Ver planes y precios
                <CursorArrowIcon className="shared-button-icon" />
              </Button>
            </ScrollLink>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PricingHero;